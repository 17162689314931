define('youtubeApi', [], function() {
  

  /* YOUTUBE API Loader

  - Use:
  youtubeApi().done(function(YTubeApi) {
  .. Api is loaded and ready on global at YT or here with YTubeApi
  });

  */

  return function() {

    var YoutubeApiC = function() {
      var component = {
        youTubeApiUrl: 'https://www.youtube.com/player_api'
      };

      component.done = function(callback) {
        component.callback = callback ? callback : '';
        component.isYoutubeConstructorReady() ? component.returnApi() : component.loadApi();
      };

      component.returnApi = function() {
        if (typeof component.callback === 'function') {
          component.callback(window.YT);
        }
      };

      component.isYoutubeConstructorReady = function() {
        return typeof YT === 'object' && typeof window.YT.Player === 'function';
      };

      component.listenForApiReady = function() {
        var youtubeConstructorCheck = function() {
          if (component.isYoutubeConstructorReady()) {
            clearInterval(component.isApiReadyCheckInterval);
            component.returnApi();
          }
        };

        component.isApiReadyCheckInterval = setInterval(youtubeConstructorCheck, 300);
      };

      component.loadApi = function() {
        var youTubejs = document.createElement('script');
        youTubejs.src = component.youTubeApiUrl;
        youTubejs.async = true;
        document.getElementsByTagName('head')[0].appendChild(youTubejs);
        component.listenForApiReady();
      };

      return component;
    };

    return YoutubeApiC();
  };

});
